<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "@/http";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const activeName = ref("uzLat");
const loading = ref(false);
const loadingPermissionGroups = ref(false);
const totalCountAll = ref(null);
const page = ref(0);
const route = useRoute();
const router = useRouter();
const form = ref({
    id: null,
    name: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
    permissionGroups: null,
    key: null,
    scopes: null,
    description: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
});
const formRef = ref(null);
const rules = ref({
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    permissionGroups: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    key: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    scopes: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

const scopes = ref([]);

const dataObject = ref({});
const permissions = ref([]);

const checkList = ref([]);

const selectedpermissionGroups = ref([]);
const allPermissionGroups = ref([]);
const data = ref([]);

function getScopes() {
    http.get("/user/scope/admin", {
        params: {
            page: 0,
            size: 100,
        },
    }).then(({ data }) => {
        scopes.value = data.content;
    });
}

function getRole(id) {
    loading.value = true;
    http.get(`user/role/admin/${id}`)
        .then(({ data }) => {
            form.value.description = data.description;
            form.value.name = data.name;
            form.value.id = data.id;
            form.value.key = data.key;
            selectedpermissionGroups.value = data.permissionGroups;

            form.value.scopes = data.scopes.map((item) => item.id);
            form.value.permissionGroups = data.permissionGroups.map((item) => item.id);
            handleChange();
        })

        .finally(() => {
            loading.value = false;
        });
}

onMounted(() => {
    if (route.params.id) {
        getRole(route.params.id);
    }
    getScopes();
    getPermissionGroups();
});

const getPermissionGroups = () => {
    loadingPermissionGroups.value = true;
    http.get("user/permission-group/admin", {
        params: {
            page: page.value,
            size: 5,
        },
    })
        .then(({ data: dataValue }) => {
            totalCountAll.value = dataValue?.totalElements;
            allPermissionGroups.value = [...allPermissionGroups?.value, ...dataValue?.content];
        })

        .finally(() => {
            loadingPermissionGroups.value = false;
        });
};

watch(allPermissionGroups, () => {
    let newArr = [];
    if (allPermissionGroups.value) {
        newArr = allPermissionGroups.value.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

        if (selectedpermissionGroups.value) {
            const newObjArr = selectedpermissionGroups.value.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            newArr = [...newObjArr, ...newArr];
        }

        data.value = [...new Map(newArr.map((item) => [item["value"], item])).values()];
    }
});

const onSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true;
            let https;

            if (form.value.id) {
                https = http.put(`user/role/admin/${form.value.id}`, form.value);
            } else {
                https = http.post("user/role/admin", {
                    name: form.value.name,
                    description: form.value.description,
                    permissionGroups: form.value.permissionGroups,
                    key: form.value.key,
                    scopes: form.value.scopes,
                });
            }
            https
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

const fetchPermissionGroupId = async (id) => {
    try {
        const response = await http.get(`user/permission/admin?permissionGroupId=${id}`);
        return response.data?.content;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

const handleChange = async () => {
    // console.log(v)
    // console.log(form.value.permissionGroups)
    for (const id of form.value.permissionGroups) {
        if (!dataObject.value[id]) {
            dataObject.value[id] = await fetchPermissionGroupId(id);
        }
    }
};

watch(dataObject.value, () => {
    const key = "id";
    permissions.value = [
        ...new Map(
            Object.values(dataObject.value)
                .flat(Infinity)
                .map((item) => [item[key], item])
        ).values(),
    ];
    checkList.value = permissions.value.map((item) => item.key);
});

const handleRemoveTag = (removedId) => {
    delete dataObject.value[removedId];
};

const handleVisibleChange = () => {
    document
        .querySelectorAll(".el-select-dropdown .el-select-dropdown__wrap")[2]
        .addEventListener("scroll", (e) => {
            const clientH = e.target.clientHeight;
            const scrollTop = e.target.scrollTop;
            const scrollH = e.target.scrollHeight;
            if (
                Math.ceil(clientH + scrollTop) >= scrollH &&
                totalCountAll.value >= page.value * 5 &&
                !loadingPermissionGroups.value
            ) {
                page.value = page.value + 1;
                window.scrollTo({
                    top: 50,
                });
                getPermissionGroups();
            }
        });
};
</script>

<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 v-if="!route.params?.id" class="page-title">
                {{ $t("titles.add_role") }}
            </h3>
            <h3 v-else class="page-title">{{ $t("titles.edit_role") }}</h3>
        </div>
        <el-row>
            <el-col :xs="24" :sm="24" :lg="24" :xl="24">
                <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-tabs v-model="activeName" class="demo-tabs">
                                <el-tab-pane :label="$t('langs.uzbek')" name="uzLat">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_name')"
                                                prop="name.uzLat"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_name')"
                                                    v-model="form.name.uzLat"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_comment')"
                                                prop="description.uzLat"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_comment')"
                                                    v-model="form.description.uzLat"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('langs.english')" name="en">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_name')"
                                                prop="name.en"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_name')"
                                                    v-model="form.name.en"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_comment')"
                                                prop="description.en"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_comment')"
                                                    v-model="form.description.en"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('langs.russian')" name="ru">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_name')"
                                                prop="name.ru"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_name')"
                                                    v-model="form.name.ru"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_comment')"
                                                prop="description.ru"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_comment')"
                                                    v-model="form.description.ru"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('langs.cyrill')" name="uzCyr">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_name')"
                                                prop="name.uzCyr"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_name')"
                                                    v-model="form.name.uzCyr"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                            <el-form-item
                                                :label="$t('labels.enter_comment')"
                                                prop="description.uzCyr"
                                            >
                                                <template #error="{ error }">
                                                    <span class="el-form-item__error">
                                                        {{ $t(error) }}
                                                    </span>
                                                </template>
                                                <el-input
                                                    size="large"
                                                    class="form-input"
                                                    :placeholder="$t('labels.enter_comment')"
                                                    v-model="form.description.uzCyr"
                                                    type="text"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                            </el-tabs>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item :label="$t('labels.select_scope')" prop="scopes">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-select
                                    v-model="form.scopes"
                                    :placeholder="$t('labels.select_scope')"
                                    size="large"
                                    :multiple="true"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in scopes"
                                        :key="item.id"
                                        :label="item.key"
                                        :value="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item :label="$t('labels.enter_key')" prop="key">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    :placeholder="$t('labels.enter_key')"
                                    v-model="form.key"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                style="margin-bottom: 5px"
                                :label="$t('labels.select_permission_group')"
                                prop="permissionGroups"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-select
                                    @change="handleChange"
                                    @remove-tag="handleRemoveTag"
                                    v-model="form.permissionGroups"
                                    :multiple="true"
                                    :placeholder="$t('labels.select_permission_group')"
                                    size="large"
                                    :filterable="true"
                                    :collapse-tags="true"
                                    popper-class="custom-select"
                                    placement="bottom"
                                    style="width: 100%"
                                    @visible-change="handleVisibleChange"
                                >
                                    <el-option
                                        v-for="item in data"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-checkbox-group
                                v-if="Object.keys(dataObject)?.length >= 1"
                                style="
                                    display: grid;
                                    grid-template-columns: repeat(3, 1fr);
                                    margin-bottom: 10px;
                                "
                                v-model="checkList"
                            >
                                <el-checkbox
                                    v-for="permission in permissions"
                                    :key="permission.id"
                                    disabled
                                    :label="permission.key"
                                />
                            </el-checkbox-group>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item style="margin-top: 10px">
                                <el-button class="custom-btn" @click="() => router.go(-1)">
                                    {{ $t("buttons.back") }}
                                </el-button>
                                <el-button class="custom-btn" type="primary" @click="onSubmit">
                                    {{ $t("buttons.save") }}
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped></style>
